<template>
  <v-container height="best_height" fill-height fluid>
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md8 class="pt-6">
        <v-row align="center" justify="center">
          <v-col cols="12" md="6">
            <v-btn
              fab
              dark
              fixed
              top
              left
              color="#7494a4"
              @click="goback"
              style="top: 80px"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-card flat>
              <v-img
                contain
                v-if="itemSelected.picture && !itemSelected.label"
                class="white"
                width="100%"
                height="40vh"
                :src="getImagePhoto(itemSelected.account, itemSelected.picture)"
                background-color="primary"
              >
              </v-img>
              <v-carousel
                v-if="itemSelected.label"
                interval="6000"
                height="250px"
                continuous
                cycle
              >
                <v-carousel-item
                  :src="getImagePhoto(itemSelected.account, itemSelected.picture)"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                />
                <v-carousel-item
                  :src="getImagePhoto(itemSelected.account, itemSelected.label)"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                />
              </v-carousel>
              <v-card-title v-else>
                <h3>{{ itemSelected.product_name }}</h3>
              </v-card-title>
              <v-card-text>
                <h3>{{ itemSelected.product_name }}</h3>
                <span v-html="itemSelected.description"></span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" v-if="$store.getters.profile.code">
            <v-row>
              <v-col cols="5" class="pt-0 pb-0">
                Pack : X {{ itemSelected.pack }}<br />
                $
                {{
                  parseFloat(itemSelected.price_uom).toFixed(2) + " x " + itemSelected.uom
                }}
              </v-col>
              <v-col cols="7">
                <v-row>
                  <v-col cols="6" class="text-right pt-0 pb-0">Sub-total</v-col>
                  <v-col cols="6" class="text-right pt-0 pb-0">
                    {{ (itemSelected.tax_base * itemSelected.qty).toLocaleString() }}
                  </v-col>
                  <v-col cols="6" class="text-right pt-0 pb-0">
                    IVA {{ itemSelected.tax }} %
                  </v-col>
                  <v-col cols="6" class="text-right pt-0 pb-0">
                    {{ (itemSelected.tax_value * itemSelected.qty).toLocaleString() }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <span v-if="itemSelected.shipping_time > 1">
                  Tiempo de entrega: {{ itemSelected.shipping_time }} días hábiles.
                </span>
                <span v-else>
                  Tiempo de entrega: {{ itemSelected.shipping_time }} día hábil.
                </span>
                <v-text-field
                  v-model="itemSelected.note"
                  outlined
                  label="Observaciones"
                  hide-details
                  class="mt-3"
                />
                <v-btn
                  color="red"
                  text
                  class="text-decoration-line-through"
                  v-if="itemSelected.discount !== 0"
                >
                  {{
                    "$" +
                    (
                      parseFloat(itemSelected.price) *
                      parseFloat(itemSelected.pack) *
                      parseFloat(itemSelected.qty)
                    ).toLocaleString()
                  }}
                </v-btn>
              </v-col>
            </v-row>
            <v-toolbar elevation="0" class="mt-6" flat>
              <v-text-field
                v-model="itemSelected.qty"
                class="center-input"
                outlined
                hide-details
                rounded
                Label="Qty"
                single-line
                prepend-inner-icon="mdi-minus"
                append-icon="mdi-plus"
                @click:prepend-inner="restQty"
                @click:append="addQty"
              />
              <v-spacer></v-spacer>

              <v-btn
                large
                v-if="addItm"
                dark
                color="success"
                class="ml-6"
                @click="add_cart(itemSelected)"
              >
                {{ parseFloat(itemSelected.tItem).toLocaleString() }}
                <v-icon right> mdi-cart </v-icon>
              </v-btn>
              <v-btn
                large
                v-else
                dark
                class="ml-6"
                color="success"
                @click="updateCart(itemSelected)"
              >
                {{ parseFloat(itemSelected.tItem).toLocaleString() }}
                <v-icon right> mdi-cart </v-icon>
              </v-btn>
            </v-toolbar>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { checkUTF8 } from "../../services/webserver.js";
export default {
  name: "CartAdd",
  components: {},
  props: {},
  mounted() {
    if (this.$route.params.item) {
      this.itemSelected = this.$route.params.item;
    } else {
      this.$router.push({ name: "/" });
    }

    if (this.$route.params.idx) {
      this.idx = this.$route.params.idx;
    }
    if (this.$route.params.addItm) {
      this.addItm = true;
    } else {
      this.addItm = false;
    }
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  data() {
    return {
      addItm: false,
      idx: 0,
      itemSelected: {},
      best_height: "100%",
      best_widht: "500",
      options_selected: null,
      optionsLst: {},
      optSelected: [],
      value_options: 0,
      cart: [],
      header_selected: [
        {
          text: "",
          align: "start",
          value: "product_name",
        },
        {
          text: "",
          align: "end",
          value: "price",
          width: 20,
        },
        {
          align: "end",
          width: 20,
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    onResize() {
      this.best_height = window.innerHeight;
      if (window.innerWidth < 480) {
        this.best_widht = window.innerWidth;
      }
    },
    deleteSelected(e) {
      var index = this.optSelected.indexOf(e);
      if (index !== -1) {
        this.optSelected.splice(index, 1);
      }
    },
    close() {
      this.$emit("close", true);
    },
    deleteItem(e) {
      var index = this.cart.indexOf(e);
      if (index !== -1) {
        this.cart.splice(index, 1);
      }
      this.updateTotals();
    },
    restQty() {
      if (this.itemSelected.qty > 1) {
        this.itemSelected.qty = this.itemSelected.qty - 1;
        this.itemSelected.tItem =
          (parseFloat(this.itemSelected.price) -
            parseFloat(this.itemSelected.discount_value)) *
          parseFloat(this.itemSelected.qty);
      }
    },
    addQty() {
      this.itemSelected.qty = this.itemSelected.qty + 1;
      this.itemSelected.tItem =
        (parseFloat(this.itemSelected.price) -
          parseFloat(this.itemSelected.discount_value)) *
        parseFloat(this.itemSelected.qty);
    },
    getImagePhoto: function (account, src) {
      var url = "https://" + this.$store.getters.getHost + "/" + account + "/" + src;
      return url;
    },
    add_cart(e) {
      e.description = checkUTF8(e.description);
      e.note = checkUTF8(e.note);
      if (localStorage.cart) {
        this.cart = JSON.parse(Buffer.from(localStorage.cart, "base64"));
        this.cart.forEach((itm) => {
          itm.qty = parseFloat(itm.qty);
          itm.price = parseFloat(itm.price);
          itm.tItem =
            (parseFloat(itm.price) - parseFloat(itm.discount_value)) *
            parseFloat(itm.qty);
        });
      } else {
        this.cart = [];
      }
      this.cart.push({ ...e });
      localStorage.cart = Buffer.from(JSON.stringify(this.cart)).toString("base64");
      if (window.history.length) {
        if (this.addItm) {
          this.$router.push({ name: "/", params: { category: e.category } });
        } else {
          this.$router.go(-1);
        }
      } else {
        this.$router.push("/");
      }
    },
    goback() {
      if (this.addItm) {
        this.$router.push({
          name: "/",
          params: { category: this.itemSelected.category },
        });
      } else {
        this.$router.go(-1);
      }
    },
    updateCart(e) {
      e.description = checkUTF8(e.description);
      e.note = checkUTF8(e.note);

      if (localStorage.cart) {
        this.cart = JSON.parse(Buffer.from(localStorage.cart, "base64"));
        this.cart.forEach((itm) => {
          itm.qty = parseFloat(itm.qty);
          itm.price = parseFloat(itm.price);
        });
        this.cart[this.idx] = e;
        localStorage.cart = Buffer.from(JSON.stringify(this.cart)).toString("base64");
      }
      this.$router.push({ name: "Cart" });
    },
    updateTotals() {
      if (localStorage.cart) {
        this.cart = JSON.parse(Buffer.from(localStorage.cart, "base64"));
        this.cart.forEach((itm) => {
          itm.qty = parseFloat(itm.qty);
          itm.price = parseFloat(itm.price);
        });
      } else {
        this.cart = [];
      }
    },
  },
  watch: {
    itemSelected: function (itm) {
      console.log(itm);
      itm.tItem =
        (parseFloat(itm.price) - parseFloat(itm.discount_value)) * parseFloat(itm.qty);
    },
  },
};
</script>

<style lang="css" scope>
.center-input {
  text-align: center;
}
</style>
